:root {
  --canvas-effects-container-top: 0;
  --canvas-effects-container-left: 0;
  --canvas-effects-container-width: 100px;
  --canvas-effects-container-height: 100px;
  --canvas-effects-container-z-index: 0;

  --font-size-primary: 1rem;
  --font-size-small: 0.875rem;

  --vz-primary-color: #fff;
  --vz-secondary-color: #202124;

  --vz-header-height: 64px;
  --vz-header-border-bottom: inset 0 -1px 0 0 rgba(0, 0, 0, 0.1);
  --vz-header-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 2px 6px 2px rgba(60, 64, 67, 0.15);
  --vz-header-border: 1px solid #bdc1c6;
  --vz-header-foreground-lower: #5f6368;
  --vz-header-foreground-lower-hover: #202124;
  --vz-header-foreground-lower-active: #202124;
  --vz-progress-background-color: #5f6368;

  --vz-heading-color: #202124;

  --accents-1: #fafafa;
  --accents-2: #eaeaea;
  --accents-3: #999;
  --accents-4: #888;
  --accents-5: #666;
  --accents-6: #444;
  --accents-7: #333;
  --accents-8: #111;

  --shadow-smallest: 0px 4px 8px rgba(0, 0, 0, 0.12);
  --shadow-small: 0 5px 10px rgba(0, 0, 0, 0.12);
  --shadow-medium: 0 8px 30px rgba(0, 0, 0, 0.12);
  --shadow-large: 0 30px 60px rgba(0, 0, 0, 0.12);

  --vz-xs-extra-small: 0px;
  --vz-sm-small: 600px;
  --vz-md-medium: 960px;
  --vz-lg-large: 1280px;
  --vz-xl-extra-large: 1920px;
}

body {
  font-family: 'Roboto', sans-serif;
}

body,
div,
ul,
form,
img,
input {
  margin: 0;
  padding: 0;
}

#root {
  position: relative;
  z-index: 1;
}

#canvas-effects-container {
  position: fixed;
  width: var(--canvas-effects-container-width);
  height: var(--canvas-effects-container-height);
  top: var(--canvas-effects-container-top);
  left: var(--canvas-effects-container-left);
  z-index: var(--canvas-effects-container-z-index);
}
a {
  text-decoration: none;
}

canvas {
  position: absolute;
  left: 0;
  top: 0;
}
