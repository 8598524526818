.welcome {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  top: calc((100vh / 2) - 225px);
  left: calc((100vw / 2) - 175px);
  width: 350px;
  height: 450px;
  border: 1px solid #ccc;
  font-size: 1em;
  line-height: 1.3;

  .welcome__title {
    width: 70%;
    margin-top: 50px;
  }
  .welcome__body {
    margin: 50px;
    display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &>p{
    text-align: center;
  }
  }
  .welcome__line {
    width: 260px;
    height: 2px;
    margin-bottom: 56px;
    background-color: black;
  }
}
